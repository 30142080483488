<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon class="position-absolute text-primary" size="40" icon="ArrowLeftCircleIcon" />
    </b-link>
    <b-form ref="form">
      <b-row>
        <b-col class="mb-4 d-flex justify-content-center align-items-center" cols="12">
          <feather-icon icon="TruckIcon" size="25" class="mr-2" />
          <h1>Nuovo Veicolo</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-1" cols="12" v-if="mediaUrl">
          <b-img :src="mediaUrl" alt="Immagine Veicolo" class="vehicle_image" />
        </b-col>
        <b-col class="mb-5 text-center" cols="10">
          <b-form-file
            v-model="mediaFile"
            :state="Boolean(mediaFile)"
            placeholder="Inserisci Immagine scegliendo un File o trascinandolo qui..."
            drop-placeholder="Trascina il File qui..."
            accept="image/*"
            @input="previewMedia"
          />
        </b-col>
        <b-col class="mb-5" cols="2">
          <b-button @click="removePreview" variant="outline-primary">Rimuovi</b-button>
        </b-col>
        <b-col class="mb-2" cols="12" md="6" lg="6">
          <b-form-group label="Nome Veicolo *" label-for="display_name">
            <b-form-input id="display_name" v-model="vehicleData.display_name" />
          </b-form-group>
        </b-col>
        <b-col class="mb-2" cols="12" md="6" lg="6">
          <b-form-group label="Anno Immatricolazione" label-for="factory_year">
            <b-form-input id="factory_year" v-model="vehicleData.factory_year" />
          </b-form-group>
        </b-col>
        <b-col class="mb-2" cols="12" md="6" lg="6">
          <b-form-group label="Tipologia *" label-for="type">
            <b-form-select id="type" v-model="vehicleData.type" :options="vehicleTypes" />
          </b-form-group>
        </b-col>
        <b-col class="mb-2" cols="12" md="6" lg="6">
          <b-form-group label="Alimentazione *" label-for="fuel_type">
            <b-form-select id="fuel_type" v-model="vehicleData.fuel_type" :options="vehicleFuelTypes" />
          </b-form-group>
        </b-col>
        <b-col class="mb-2" cols="12" md="6" lg="6">
          <b-form-group label="Modello *" label-for="model">
            <b-form-input id="model" v-model="vehicleData.model" />
          </b-form-group>
        </b-col>
        <b-col class="mb-2" cols="12" md="6" lg="6">
          <b-form-group label="N° Posti" label-for="seat_number">
            <b-form-input id="seat_number" v-model="vehicleData.seat_number" />
          </b-form-group>
        </b-col>
        <b-col class="mb-2" cols="12">
          <b-form-group label="Note" label-for="note">
            <b-form-textarea id="note" v-model="vehicleData.note" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-2" cols="12">
          <h2>Caratteristiche</h2>
          <label for="features">Premi Invio per inserire la Caratteristica</label>
          <b-form-tags v-model="vehicleData.features" input-id="features" class="mb-2" />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right mt-2" cols="12">
          <b-button
            @click="addVehicle"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <b-spinner v-if="loading" variant="light" small />
            <span v-if="!loading">Aggiungi</span>
          </b-button>
          <b-button
            @click.prevent="reset"
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Pulisci
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormTextarea,
  BFormTags,
  BLink,
  BFormFile,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import vehiclesStoreModule from '../vehiclesStoreModule'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormTags,
    BLink,
    BFormFile,
    BImg,
    BSpinner,
  },
  setup() {
    const VEHICLES_APP_STORE_MODULE_NAME = 'app-vehicles'

    // Register module
    if (!store.hasModule(VEHICLES_APP_STORE_MODULE_NAME)) {
      store.registerModule(VEHICLES_APP_STORE_MODULE_NAME, vehiclesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLES_APP_STORE_MODULE_NAME)) store.unregisterModule(VEHICLES_APP_STORE_MODULE_NAME)
    })

    return {
      vehicleData: {
        display_name: null,
        factory_year: null,
        type: null,
        model: null,
        note: null,
        fuel_type: null,
        seat_number: 4,
        features: [],
      },
      vehicleTypes: [
        { value: null, text: 'Seleziona una Tipologia' },
        { value: 'Auto', text: 'Auto' },
        { value: 'Furgone', text: 'Furgone' },
        { value: 'Camion', text: 'Camion' },
      ],
      vehicleFuelTypes: [
        { value: null, text: 'Seleziona un tipo di Alimentazione' },
        { value: 'diesel', text: 'Diesel' },
        { value: 'gasoline', text: 'Benzina' },
        { value: 'methanol', text: 'Metano' },
        { value: 'gpl', text: 'GPL' },
        { value: 'electric', text: 'Elettrica' },
        { value: 'hydrogen', text: 'Idrogeno' },
      ],
      loading: false,
      mediaFile: null,
      mediaUrl: null,
    }
  },
  methods: {
    previewMedia() {
      if (this.mediaFile) this.mediaUrl = URL.createObjectURL(this.mediaFile)
    },
    removePreview() {
      URL.revokeObjectURL(this.mediaUrl)
      this.mediaUrl = null
      this.mediaFile = null
    },
    goBack() {
      this.$router.go(-1)
    },
    reset() {
      this.$refs.form.reset()
    },
    addVehicle() {
      const valid = this.validation()
      if (!valid) return
      this.loading = true
      const formData = new FormData()
      formData.append('display_name', this.vehicleData.display_name)
      formData.append('factory_year', this.vehicleData.factory_year)
      formData.append('type', this.vehicleData.type)
      formData.append('fuel_type', this.vehicleData.fuel_type)
      formData.append('model', this.vehicleData.model)
      formData.append('seat_number', this.vehicleData.seat_number)
      formData.append('note', this.vehicleData.note)
      if (this.vehicleData.features && this.vehicleData.features.length > 0)
        formData.append('features', this.vehicleData.features)
      if (this.mediaFile) formData.append('image', this.mediaFile)
      store
        .dispatch('app-vehicles/addVehicle', formData)
        .then((result) => {
          this.$router.replace({ name: 'apps-vehicles-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Veicolo #${result.data.id} aggiunto con successo`,
                icon: 'TruckIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch((error) => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            this.$router.replace({ name: 'auth-login' }).then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
          }
        })
      this.loading = false
    },
    validation() {
      if (!this.vehicleData.display_name) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Nome',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Nome del Veicolo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.vehicleData.type) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Tipologia',
            icon: 'AlertTriangleIcon',
            text: 'Seleziona una Tipologia di Veicolo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.vehicleData.fuel_type) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Alimentazione',
            icon: 'AlertTriangleIcon',
            text: 'Seleziona un tipo di Alimentazione del Veicolo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.vehicleData.model) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Modello',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Modello del Veicolo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
.vehicle_image {
  width: 100%;
  max-height: 500px;
  object-position: center;
  object-fit: cover;
  border-radius: 5px;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Sfoglia';
}
</style>
